import "./Footer.css";

const Footer = () => (
  <footer className="footer">
    <a href="mailto:contact@blackout.restaurant" className="link footer__link">
      contact@blackout.restaurant
    </a>
  </footer>
);

export default Footer;
